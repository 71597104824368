import type { RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
	{
		path: '',
		name: 'Auth',
		redirect: '/login',
		component: () => import('../layouts/blank.vue'),
		children: [
			{
				path: '/welcome',
				name: 'Verification',
				component: () => import('../pages/welcome/index.vue'),
			},
			{
				path: '/welcome/start',
				name: 'Onboarding Start',
				component: () => import('../pages/welcome/essential/start.vue'),
			},
			{
				path: '/welcome/measurements',
				name: 'Onboarding Measurements',
				component: () => import('../pages/welcome/essential/measurements.vue'),
			},
			{
				path: '/welcome/goal',
				name: 'Onboarding Goal',
				component: () => import('../pages/welcome/goal/goal.vue'),
			},
			{
				path: '/welcome/goal/weight',
				name: 'Onboarding Goal Weight',
				component: () => import('../pages/welcome/goal/weight.vue'),
			},
			{
				path: '/welcome/goal/timeframe',
				name: 'Onboarding Goal Timeframe',
				component: () => import('../pages/welcome/goal/timeframe.vue'),
			},
			{
				path: '/welcome/diet',
				name: 'Onboarding Diet',
				component: () => import('../pages/welcome/diet/diet.vue'),
			},
			{
				path: '/welcome/diet/allergies',
				name: 'Onboarding allergies',
				component: () => import('../pages/welcome/diet/allergies.vue'),
			},
			{
				path: '/welcome/diet/macronutrients',
				name: 'Onboarding macronutrients',
				component: () => import('../pages/welcome/diet/macronutrients.vue'),
			},
			{
				path: '/welcome/diet/frequency',
				name: 'Onboarding diet frequency',
				component: () => import('../pages/welcome/diet/frequency.vue'),
			},
			{
				path: '/welcome/exercise',
				name: 'Onboarding exercise',
				component: () => import('../pages/welcome/exercise/exercise.vue'),
			},
			{
				path: '/welcome/exercise/frequency',
				name: 'Onboarding exercise frequency',
				component: () => import('../pages/welcome/exercise/frequency.vue'),
			},
			{
				path: '/login',
				name: 'Login',
				component: () => import('../pages/auth/login.vue'),
			},
			{
				path: '/passwordless',
				name: 'Passwordless',
				component: () => import('../pages/auth/passwordless.vue'),
			},
			{
				path: '/reset',
				name: 'Reset Password',
				component: () => import('../pages/auth/reset.vue'),
			},
			{
				path: '/logout',
				name: 'Logout',
				component: () => import('../pages/auth/logout.vue'),
			},
			{
				path: '/join',
				name: 'Join',
				component: () => import('../pages/auth/join.vue'),
			},
		],
	},
	{
		path: '',
		name: 'Root',
		redirect: '/login',
		component: () => import('../layouts/dashboard.vue'),
		children: [
			{
				path: '/dashboard',
				name: 'Dashboard',
				component: () => import('../pages/dashboard/dashboard.vue'),
			},
			{
				path: '/diet',
				name: 'Diet',
				component: () => import('../pages/dashboard/diet/index.vue'),
			},
			{
				path: '/exercise',
				name: 'Exercise',
				component: () => import('../pages/dashboard/exercise/index.vue'),
			},
			{
				path: '/exercise/program',
				name: 'Exercise Schedule',
				component: () => import('../pages/dashboard/exercise/Schedule.vue'),
			},
			{
				path: '/exercise/program/:id',
				name: 'Exercise Program',
				component: () => import('../pages/dashboard/exercise/program.vue'),
			},
			{
				path: '/exercise/complete',
				name: 'Exercise Complete',
				component: () => import('../pages/dashboard/exercise/complete.vue'),
			},
			{
				path: '/coach',
				name: 'Coach',
				component: () => import('../pages/dashboard/coach/index.vue'),
			},
			{
				path: '/profile',
				name: 'Profile',
				component: () => import('../pages/dashboard/profile/index.vue'),
			},
		],
	},
]

export default routes
